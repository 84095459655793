import { type MutableRefObject } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { AtlasBodyMain, AtlasBodySubtitle } from '@justomx/atlas-components'
import type { ProductDetail } from 'types/receipt/model'
import { ConfirmationDialogMolecule } from 'components/molecules/ConfirmationDialog.molecule'
import { RadioButton } from 'components/molecules/RadioButton.molecule'
import { RECEIPTS } from 'utils/constants'
import { getCurrentCountry } from 'client/utilities.client-helper'

interface MissingDialogProps {
  dialogRef: MutableRefObject<HTMLDialogElement | null>
  missingCount: number
  product: ProductDetail
  receiptType: string
  newTransfersFlow: boolean
  receivedAmount: number
  coninueAction: () => void
  onMissingReasonChange: (value: string) => void
}

const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 24px;
`

const labelByMeasureUnit = {
  KG: 'title-kg',
  PIEZA: 'title',
  CAJA: 'title-box'
}

const transfersLabels = {
  KG: 'transfers.title-kg',
  PIEZA: 'transfers.title',
  CAJA: 'transfers.title-box'
}

const transfersLabelsV2 = {
  KG: 'transfers.label-kg',
  PIEZA: 'transfers.label',
  CAJA: 'transfers.label-box'
}

export const MissingDialogOrganism = (props: MissingDialogProps) => {
  const { t } = useTranslation('global')
  const {
    dialogRef,
    missingCount,
    product,
    receiptType,
    newTransfersFlow,
    receivedAmount,
    coninueAction,
    onMissingReasonChange
  } = props
  const country = getCurrentCountry()

  if (receiptType === 'providers' || !newTransfersFlow) {
    return (
      <ConfirmationDialogMolecule
        dialogRef={dialogRef}
        confirmText={
          receiptType === RECEIPTS.transfers
            ? t('commons.accept')
            : t('receipt-missing-dialog.continue')
        }
        onConfirm={coninueAction}
        cancelText={t('receipt-missing-dialog.back')}
        confirmOnly={receiptType === RECEIPTS.transfers}
      >
        {receiptType === RECEIPTS.providers && (
          <AtlasBodySubtitle>
            {t(
              `receipt-missing-dialog.${labelByMeasureUnit[product.measureUnit]}`,
              { missing: missingCount.toLocaleString('en-US') }
            )}
          </AtlasBodySubtitle>
        )}
        {receiptType === RECEIPTS.transfers && (
          <AtlasBodySubtitle>
            {t(
              `receipt-missing-dialog.${transfersLabels[product.measureUnit]}`,
              {
                missing: missingCount.toLocaleString('en-US')
              }
            )}
          </AtlasBodySubtitle>
        )}

        {receiptType === RECEIPTS.providers && (
          <DialogBody>
            <RadioButton
              defaultChecked
              name="reason"
              id="reject"
              value="reject"
              label={t('receipt-missing-dialog.reject')}
              onChange={onMissingReasonChange}
            />
            {country !== 'BR' && (
              <RadioButton
                name="reason"
                id="partialmissing"
                value="partialmissing"
                label={t('receipt-missing-dialog.partial-missing')}
                onChange={onMissingReasonChange}
              />
            )}
          </DialogBody>
        )}
      </ConfirmationDialogMolecule>
    )
  }

  return (
    <ConfirmationDialogMolecule
      dialogRef={dialogRef}
      confirmText={
        receiptType === RECEIPTS.transfers
          ? t('commons.confirm')
          : t('receipt-missing-dialog.continue')
      }
      onConfirm={coninueAction}
      cancelText={
        receiptType === RECEIPTS.transfers
          ? t('commons.cancel')
          : t('receipt-missing-dialog.back')
      }
    >
      {receiptType === RECEIPTS.transfers && (
        <>
          <AtlasBodySubtitle>
            {t('receipt-missing-dialog.transfers.new-title', {
              missing: missingCount.toLocaleString('en-US')
            })}
          </AtlasBodySubtitle>

          <DialogBody>
            <AtlasBodyMain>
              {t(
                `receipt-missing-dialog.${transfersLabelsV2[product.measureUnit]}`,
                { amount: receivedAmount.toLocaleString('en-US') }
              )}
            </AtlasBodyMain>
          </DialogBody>
        </>
      )}

      {receiptType === RECEIPTS.providers && (
        <>
          <AtlasBodySubtitle>
            {t(
              `receipt-missing-dialog.${labelByMeasureUnit[product.measureUnit]}`,
              { missing: missingCount.toLocaleString('en-US') }
            )}
          </AtlasBodySubtitle>
          <DialogBody>
            <RadioButton
              defaultChecked
              name="reason"
              id="reject"
              value="reject"
              label={t('receipt-missing-dialog.reject')}
              onChange={onMissingReasonChange}
            />
            {country !== 'BR' && (
              <RadioButton
                name="reason"
                id="partialmissing"
                value="partialmissing"
                label={t('receipt-missing-dialog.partial-missing')}
                onChange={onMissingReasonChange}
              />
            )}
          </DialogBody>
        </>
      )}
    </ConfirmationDialogMolecule>
  )
}
