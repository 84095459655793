import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  AtlasBodyTitle,
  AtlasColor,
  AtlasH1,
  Icon,
  Icons,
  SnackbarInformationPanel
} from '@justomx/atlas-components'
import { useScanner } from 'hooks/scanner.hook'

interface ScannerProps {
  label: string
  onScan: (scanned: string) => void
  gap?: number
  cardMode?: boolean
  cardHeight?: number
}

const ScannerContainer = styled.div<{ $gap?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  gap: ${({ $gap }) => $gap ?? 32}px;
`

export const ScannerMolecule = (props: ScannerProps) => {
  const { t } = useTranslation('global')
  const { keys, getScan } = useScanner()
  const { label, onScan, cardMode, gap, cardHeight } = props

  useEffect(() => {
    const scanned = getScan()
    if (scanned) {
      onScan(scanned)
    }
  }, [keys])

  return (
    <>
      {cardMode
        ? (<SnackbarInformationPanel
            style={{
              border: '1px solid',
              borderRadius: '12px',
              backgroundColor: AtlasColor.Primary10,
              borderColor: AtlasColor.Important,
              alignItems: 'center',
              height: cardHeight
            }}
          >
            <Icon src={Icons.ScanIcon} size={40} />
            <AtlasBodyTitle>
              { label }
            </AtlasBodyTitle>
          </SnackbarInformationPanel>)
        : (<ScannerContainer $gap={gap}>
              <Icon src={Icons.ScanIcon} />
              <AtlasBodyTitle>{t('scanner-molecule.title')}</AtlasBodyTitle>
              <AtlasH1 style={{ textAlign: 'center' }}>{label}</AtlasH1>
          </ScannerContainer>)}
    </>
  )
}
