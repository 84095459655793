import { PageLayoutAtom } from '@justomx/atlas-components'
import { createGlobalStyle, StyleSheetManager } from 'styled-components'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { RouterProvider } from 'react-router-dom'
import { router } from './pages/router'
import { isProduction } from './utils/environment'
import i18n from 'i18next'
import {
  I18nextProvider,
  initReactI18next,
  useTranslation
} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import esGlobal from './i18n/es/global.json'
import brGlobal from './i18n/pt_BR/global.json'
import { useEffect } from 'react'
import './analytics/analytics.utils'
import { getCurrentCountry, getUser } from 'client/utilities.client-helper'
import { gb } from 'utils/growthbook'
import { GrowthBookProvider } from '@growthbook/growthbook-react'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    interpolation: { escapeValue: false },
    lng: 'es',
    resources: {
      es: { global: esGlobal },
      pt: { global: brGlobal }
    }
  })
  .catch((err) => {
    console.error(err)
  })

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* Atlas/Body Titles */
    font-style: normal;
    font-weight: 400;
  }
`

const countryToLanguage: Record<string, string> = {
  mx: 'es',
  br: 'pt'
}

declare global {
  interface Window {
    WVMetadata?: Record<string, string>
  }
}

const defaultLanguage = 'es'

gb.init().catch((err) => {
  console.error(err)
})

export function App () {
  console.log('Starting app! Is production? ', isProduction())
  const { i18n } = useTranslation('global')

  useEffect(() => {
    if (window.WVMetadata != null) {
      const country = getCurrentCountry().toLowerCase()
      i18n
        .changeLanguage(countryToLanguage[country] ?? defaultLanguage)
        .catch((err) => {
          console.error(err)
        })
    } else {
      console.error('window.WVMetadata is UNDEFINED!')
    }

    const user = getUser()
    const gbAttrs = {
      warehouseCode: user.warehouse.toUpperCase(),
      country: user.country.toUpperCase(),
      email: user.username
    }
    gb.setAttributes(gbAttrs)
      .then(() => {
        console.info('Attributes have been modified in Growthbook', gbAttrs)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  return (
    <GrowthBookProvider growthbook={gb}>
      <I18nextProvider i18n={i18n}>
        <StyleSheetManager>
          <GlobalStyle />
          <PageLayoutAtom>
            <RouterProvider router={router} />
          </PageLayoutAtom>
          <ToastContainer
            theme="dark"
            position="bottom-center"
            toastClassName="toastErrorClass"
          />
        </StyleSheetManager>
      </I18nextProvider>
    </GrowthBookProvider>
  )
}
