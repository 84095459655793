import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  AtlasBodyMain,
  AtlasBodySubtitle,
  AtlasBodyTitle,
  CardComponent,
  Divider,
  Icon,
  Icons,
  PageContentAtom,
  SecondaryButtonAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'

import type { ReceiptProductSummary } from 'types/receipt/model'
import { useSummaryData } from './Summary.hooks'
import { CardImageMolecule } from 'components/molecules/CardImage.molecule'
import { FlexColumnAtom } from 'components/atoms/FlexColumn.atom'
import { FlexRowAtom } from 'components/atoms/FlexRow.atom'
import { FlexSpaceBetweenAtom } from 'components/atoms/FlexSpaceBetween.atom'
import { ConfirmationDialogMolecule } from 'components/molecules/ConfirmationDialog.molecule'
import { S3ImagesPreviewMolecule } from 'components/molecules/S3ImagesPreview.molecule'
import LoaderAtom from 'components/atoms/Loader.atom'
import { CounterCardAtom } from 'components/atoms/CounterCard.atom'
import { MEASURE_UNITS } from 'utils/constants'

const IconButton = styled(SecondaryButtonAtom)`
  border-radius: 100px;
  width: 40px;
  height: 40px;
`

const CardTitle = ({ status, receipt, measureUnit }) => {
  const { t } = useTranslation('global')
  if (status === 'missing') {
    return t(
      measureUnit === 'KG'
        ? 'receipt-summary.amount-kg'
        : 'receipt-summary.amount',
      {
        amount:
          measureUnit === MEASURE_UNITS.kg
            ? receipt.amount.toLocaleString('en-US')
            : receipt.amount
      }
    )
  } else {
    return t('receipt-summary.lot', { lot: receipt.lot })
  }
}

const CardContent = (props: {
  status: string
  receipt: ReceiptProductSummary
  measureUnit: string
}) => {
  const { t } = useTranslation('global')
  const { status, receipt, measureUnit } = props
  const getReasons = (reasonKeys: string[] | undefined) =>
    reasonKeys
      ?.map((reason) => t(`receipt-rejection.reasons.${reason}`))
      .join(', ')

  return {
    received: (
      <>
        <AtlasBodyMain>
          {t(
            measureUnit === MEASURE_UNITS.kg
              ? 'receipt-summary.amount-kg'
              : 'receipt-summary.amount',
            {
              amount:
                measureUnit === MEASURE_UNITS.kg
                  ? receipt.amount.toLocaleString('en-US')
                  : receipt.amount
            }
          )}
        </AtlasBodyMain>
        <AtlasBodyMain>
          {t('receipt-summary.expiration', {
            expiration: receipt.expirationDate
          })}
        </AtlasBodyMain>
        <AtlasBodyMain>
          {t('receipt-summary.container', { container: receipt.container })}
        </AtlasBodyMain>
      </>
    ),
    rejected: (
      <>
        <AtlasBodyMain>
          {t(
            measureUnit === MEASURE_UNITS.kg
              ? 'receipt-summary.amount-kg'
              : 'receipt-summary.amount',
            {
              amount:
                measureUnit === MEASURE_UNITS.kg
                  ? receipt.amount.toLocaleString('en-US')
                  : receipt.amount
            }
          )}
        </AtlasBodyMain>
        <AtlasBodyMain>
          {t('receipt-summary.reasons', {
            reasons: getReasons(receipt.rejectReasons)
          })}
        </AtlasBodyMain>
        <S3ImagesPreviewMolecule
          s3Keys={receipt.evidences || []}
          imgSize={46}
          margin={0}
          gap={4}
        />
      </>
    ),
    missing: (
      <AtlasBodyMain>{t('receipt-summary.missing-reason')}</AtlasBodyMain>
    )
  }[status]
}

const SummaryPage = () => {
  const { t } = useTranslation('global')
  const {
    product,
    summary,
    total,
    dialogRef,
    loading,
    readonly,
    expectedQuantity,
    actions,
    loaderData
  } = useSummaryData()

  if (!product) {
    return null
  }

  const productSavedInInventory = loaderData?.received?.lots[0]?.isSavedInInventory || false;

  return (
    <>
      {loading && <LoaderAtom>{t('loader.message')}</LoaderAtom>}

      <ToolbarMolecule
        title={t('receipt-summary.title')}
        actionIcon={Icons.CloseIcon}
        onAction={actions.onCloseSummary}
      />
      <PageContentAtom>
        <CounterCardAtom
          title={t(
            product.measureUnit === 'KG'
              ? 'receipt-product-lots.counter-kg'
              : 'receipt-product-lots.counter',
            {
              counter: total.toLocaleString('en-US'),
              total: expectedQuantity.toLocaleString('en-US')
            }
          )}
          subtitle={t(
            product.measureUnit === 'KG'
              ? 'receipt-product-lots.requested-quantity'
              : 'receipt-product-lots.requested-boxes'
          )}
          titleSize={product.measureUnit === MEASURE_UNITS.kg ? 'h2' : 'h1'}
        ></CounterCardAtom>

        <CardImageMolecule
          imageURL={product.imgUrl}
          textLines={[
            t('receipt-order-detail.upc', { upc: product.barcode }),
            product.description,
            t(
              product.measureUnit === 'KG'
                ? 'receipt-product-lots.measure-kg'
                : 'receipt-product-lots.measure',
              {
                measure:
                  product.measureUnit === 'KG'
                    ? product.unitsOfMeasurment * 1000
                    : product.unitsOfMeasurment
              }
            )
          ]}
        />

        <FlexColumnAtom $gap={32}>
          {Object.keys(summary).map((status) => (
            <FlexColumnAtom key={status} $gap={16}>
              <AtlasBodyTitle>
                {t(`receipt-summary.status.${status}`)}
              </AtlasBodyTitle>

              {summary[status].map((receipt, idx) => (
                <CardComponent key={`${status}${idx}`}>
                  <FlexSpaceBetweenAtom>
                    <AtlasBodySubtitle>
                      <CardTitle
                        status={status}
                        receipt={receipt}
                        measureUnit={product.measureUnit}
                      ></CardTitle>
                    </AtlasBodySubtitle>

                    {(!readonly && !productSavedInInventory) && (
                      <FlexRowAtom $gap={16}>
                        <IconButton
                          onClick={() => {
                            actions.openDeleteDialog({ receipt, idx })
                          }}
                        >
                          <Icon src={Icons.IcDelete} />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            actions.onEdit(receipt)
                          }}
                        >
                          <Icon src={Icons.IcEditPrimary} />
                        </IconButton>
                      </FlexRowAtom>
                    )}
                  </FlexSpaceBetweenAtom>

                  <Divider />

                  <FlexColumnAtom $gap={12}>
                    <CardContent
                      status={status}
                      receipt={receipt}
                      measureUnit={product.measureUnit}
                    ></CardContent>
                  </FlexColumnAtom>
                </CardComponent>
              ))}
            </FlexColumnAtom>
          ))}
        </FlexColumnAtom>

        <ConfirmationDialogMolecule
          dialogRef={dialogRef}
          confirmText={t('commons.continue')}
          onConfirm={actions.onDelete}
          cancelText={t('commons.cancel')}
        >
          <AtlasBodySubtitle>
            {t('receipt-summary.confirm-delete')}
          </AtlasBodySubtitle>
        </ConfirmationDialogMolecule>
      </PageContentAtom>
    </>
  )
}

export default SummaryPage
