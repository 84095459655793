const prodUrls = [
  'https://op-receipt-web.pages.dev/',
  'https://op-receipt-web.justo.cloud/'
]

export const isProduction = () => {
  return prodUrls.some((e) => location.href.startsWith(e))
}

export const isLocal = () => location.href.startsWith('http://localhost')

export const getLoginUrl = () => {
  if (isProduction()) {
    return 'https://operations-login.pages.dev/'
  }

  return 'https://staging.operations-login.pages.dev/'
}
