import { type LoggedUser, UserManagment } from '@justomx/webview-commons'
import { checkProperJWT } from '../utils/checkJWT.util'

export interface CommonHeaders {
  'x-hardcoded': boolean
  authorization: string
  'x-authorization-wms': string
  'x-authorization-core': string
  'x-justo-country': string
  'x-justo-warehouse'?: string
}

export const getUser = () => {
  let user: LoggedUser | undefined
  try {
    user = UserManagment.getCurrentUser()
  } catch {
    user = undefined
  }

  if (user?.authorizationWms && checkProperJWT(user.authorizationWms)) {
    return user
  }

  throw new Error('Autorización inválida (estás intentando acceder desde Atlas-wms?).')
}

export const getCommonHeaders = (): CommonHeaders => {
  const user = getUser()

  if (user) {
    return {
      'x-hardcoded': false,
      authorization: `Bearer ${user.authorizationWms}`,
      'x-authorization-wms': `Bearer ${user.authorizationWms}`,
      'x-authorization-core': `Bearer ${user.authorizationCore}`,
      'x-justo-country': user.country?.toUpperCase(),
      'x-justo-warehouse': user.warehouse?.toUpperCase()
    }
  }

  throw new Error('Autorización inválida (estás intentando acceder desde Atlas-wms?).')
}

export function getCurrentCountry () {
  const user = getUser()

  if (user) {
    return user.country?.toUpperCase()
  }

  console.log('Metadata of WV is undefined, using default values.')
  return 'MX'
}

export function getCurrentWarehouse (): string {
  const user = getUser()

  if (user) {
    return user.warehouse?.toUpperCase()
  }

  console.log('Metadata of WV is undefined, using default values.')
  return 'PP'
}
