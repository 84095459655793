import { useRef, useState } from 'react'
import { useAtomValue } from 'jotai'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Analytics } from 'analytics/analytics.events'
import { useRequiredParams } from 'hooks/params.hook'
import { containerToMoveAtom } from '../Receipt.state'
import { useGoToMoveContainers } from '../move-containers/MoveContainers.navigator'
import { moveContainer } from 'client/receipt/receipt.client'
import { toastSuccess } from 'utils/toast'
import { RECEIPT_LOCATION_TYPE } from 'utils/constants'

export const useScanLocationData = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('global')
  const [loading, setLoading] = useState(false)
  const container = useAtomValue(containerToMoveAtom)
  const moveContainers = useGoToMoveContainers()
  const { orderId, receiptId, receiptType } = useRequiredParams(
    'orderId',
    'receiptId',
    'receiptType'
  )
  const loadingRef = useRef(false)

  const onScan = async (scanned: string) => {
    if (!container || loadingRef.current) {
      console.info('skipping move container due to current request')
      return
    }
    loadingRef.current = true
    setLoading(true)
    const eventData = {
      orderId,
      receiptType,
      originContainerName: container?.containerName,
      destinationLocationName: scanned,
      destinationLocationType: RECEIPT_LOCATION_TYPE
    }

    try {
      Analytics.receipt.tryMoveContainer(eventData)
      await moveContainer(receiptId, container.containerName, scanned)
      Analytics.receipt.moveContainer(eventData)

      toastSuccess(t('receipt-scan-location.scanned'))
      moveContainers.go(receiptId, orderId)
    } catch (error) {
      Analytics.receipt.moveContainerError({
        ...eventData,
        error: error.toString()
      })
      console.error(error)
    } finally {
      loadingRef.current = false
      setLoading(false)
    }
  }

  return {
    container,
    loading,
    actions: {
      onScan,
      goBack: () => {
        navigate(-1)
      }
    }
  }
}
