import { GrowthBook } from '@growthbook/growthbook-react'
import { isProduction } from './environment'

export const gb = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: isProduction()
    ? 'key_prod_2e5039e3df31016f'
    : 'key_dev_dc26a16e05a761c4',
  enableDevMode: true
})
