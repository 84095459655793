import { getWarehouseByWmsName } from '@justomx/warehouse-commons'
import { type LoggedUser, UserManagment } from '@justomx/webview-commons'
import {
  getCurrentCountry,
  getCurrentWarehouse
} from 'client/utilities.client-helper'
import { checkProperJWT } from '../utils/checkJWT.util'

export const useSessionData = () => {
  const country = getCurrentCountry()
  const warehouseCode = getCurrentWarehouse()
  const warehouse = getWarehouseByWmsName(warehouseCode)
  let user: LoggedUser | undefined

  try {
    user = UserManagment.getCurrentUser()
  } catch {}

  if (user?.authorizationWms && checkProperJWT(user.authorizationWms)) {
    return { user, warehouse, country }
  }

  throw new Error('Autorización inválida (estás intentando acceder desde Atlas-wms?)')
}
