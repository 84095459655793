import { isLocal } from './environment'

export const checkProperJWT = (authorization: string) => {
  if (authorization.length && isLocal()) return true

  const [_header, payload, _signature] = authorization.split('.')
  const body = JSON.parse(atob(payload))
  const properIssuer =
    body.iss?.startsWith('https://auth.justo.cloud/realms/operations-') ||
    body.iss?.startsWith('https://auth.dev.justo.cloud/realms/operations-')
  const expiration =
    typeof body.exp === 'number' && body.exp * 1000 > new Date().getTime()
  return properIssuer && expiration
}
