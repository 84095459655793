import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

import type {
  FormikLotFormValues,
  LotFormValues,
  ProductDetail,
  UpdateMissingsData
} from 'types/receipt/model'
import { useQueryParam, useRequiredParams } from 'hooks/params.hook'
import { useConfirmationDialog } from 'components/molecules/ConfirmationDialog.molecule'
import {
  containerTypeAtom,
  scannedContainer,
  selectedProductAtom
} from '../Receipt.state'
import { useGoToPartialMissing } from '../partial-missing/PartialMissing.navigator'
import { useGoToRejection } from '../rejection/Rejection.navigator'
import { useGoToContainerType } from '../container-type/ContainerType.navigator'
import { useGoToOrderDetail } from '../order-detail/OrderDetail.navigator'
import { useGoToMoveContainers } from '../move-containers/MoveContainers.navigator'
import {
  getCurrentTotal,
  getUpsertData,
  getValidationSchema,
  mapLotFormValues
} from './ProductLots.helpers'
import { isProductLotsData } from './ProductLots.loader'
import { Analytics } from 'analytics/analytics.events'
import {
  getOrderDetail,
  updateMissings,
  upsertProductLots
} from 'client/receipt/receipt.client'
import { RECEIPTS, MEASURE_UNITS, PRODUCT_STATUS } from 'utils/constants'
import { toastSuccess } from 'utils/toast'

export const emptyForm = {
  lot: '',
  expirationDate: '',
  numberOfUnits: '',
  amountOfBoxes: '',
  containerName: ''
}

export const useProductLotsData = () => {
  const { t } = useTranslation('global')
  const data = useLoaderData()
  const [containerType, setContainerType] = useAtom(containerTypeAtom)
  const [product, setProduct] = useAtom(selectedProductAtom)
  const [container, _] = useAtom(scannedContainer)
  const [boxesCounter, setBoxesCounter] = useState(0)
  const [missingCounter, setMissingCounter] = useState(0)
  const [expectedQuantity, setExpectedQuantity] = useState(0)
  const [loading, setLoading] = useState(false)
  const [missingReason, setMissingReason] = useState('')
  const [lotsSaved, setLotsSaved] = useState(false)
  const [boxesCounterReady, setBoxesCounterReady] = useState(false)
  const navigate = useNavigate()
  const partialMissing = useGoToPartialMissing()
  const rejection = useGoToRejection()
  const containerTypePage = useGoToContainerType()
  const orderDetail = useGoToOrderDetail()
  const moveContainers = useGoToMoveContainers()
  const { orderId, receiptId, productId, receiptType } = useRequiredParams(
    'orderId',
    'receiptId',
    'productId',
    'receiptType'
  )
  const editionMode = useQueryParam('editionMode') === 'true'
  const { dialogRef: missingDialogRef, open: openMissingDialog } =
    useConfirmationDialog()
  const newTransfersFlow = useFeatureIsOn('receipt_web_transfers_v2')

  if (!isProductLotsData(data)) {
    throw new Error(t('receipt-product-lots.type-error'))
  }

  const initialValues = {
    forms: [...mapLotFormValues(data.lots, containerType, container)]
  }
  const formConfig = useFormik<FormikLotFormValues>({
    initialValues,
    validateOnMount: true,
    validationSchema: getValidationSchema(t, product, data, container),
    onSubmit: async (values) => {
      await saveLotValues(values.forms, true)
    }
  })

  useEffect(() => {
    if (product && boxesCounterReady) {
      const fullReceipt = boxesCounter >= expectedQuantity
      setLotsSaved(!!data.lots.length || fullReceipt)
    }
  }, [data, boxesCounterReady, product])

  useEffect(() => {
    if (product) {
      calculateCurrentTotal(product)
      if (lotsSaved && !editionMode && boxesCounterReady) {
        onValidateTotals()
      }
    }
  }, [product, lotsSaved, boxesCounterReady])

  useEffect(() => {
    if (product) {
      calculateCurrentTotal(product)
    }
  }, [formConfig.values.forms])

  useEffect(() => {
    const defaultMissingReason =
      receiptType === RECEIPTS.providers ? 'reject' : 'partialmissing'
    setMissingReason(defaultMissingReason)
  }, [receiptType])

  const calculateCurrentTotal = (product: ProductDetail) => {
    const { measureUnit, quantity, totalUnits } = product
    const currentTotal = getCurrentTotal(
      formConfig.values.forms,
      measureUnit,
      data
    )
    setBoxesCounter(currentTotal)
    setBoxesCounterReady(true)

    let expectedQuantity = totalUnits
    if (measureUnit === MEASURE_UNITS.box) {
      expectedQuantity = quantity
    }
    setExpectedQuantity(expectedQuantity)

    const missingCounter = expectedQuantity - currentTotal
    setMissingCounter(missingCounter)
  }

  const onValidateTotals = () => {
    formConfig
      .validateForm()
      .then(async () => {
        if (boxesCounter < expectedQuantity) {
          openMissingDialog()
        } else {
          await validateLastProduct()
        }
      })
      .catch(() => {})
  }

  const validateLastProduct = async () => {
    if (!product) {
      return
    }

    try {
      setLoading(true)
      const detail = await getOrderDetail(receiptId)
      const pending = detail.products.filter(
        (product) => product.status === PRODUCT_STATUS.pending
      )
      setProduct(undefined)
      toastSuccess(t('receipt-summary.received'))

      if (pending.length > 0) {
        goToOrderDetail()
      } else {
        moveContainers.go(receiptId, orderId)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const saveLotValues = async (
    values: LotFormValues[],
    validateTotals?: boolean
  ) => {
    try {
      if (!product || !container) {
        return
      }

      setLoading(true)
      const data = getUpsertData(receiptId, container, product, values)
      await upsertProductLots(data)
      Analytics.receipt.addLots(
        data.lots.map((value) => ({
          orderId,
          receiptType,
          lot: value?.lotCode,
          expirationDate: value?.expirationDate,
          quantity: value?.units,
          boxes: value?.boxes,
          originContainerName: value?.containerName
        })),
        product
      )

      if (validateTotals) {
        onValidateTotals()
      }
    } catch (error) {
      console.error(error)
      throw new Error('Ocurrió un error al tratar de crear los lotes')
    } finally {
      setContainerType('')
      setLoading(false)
    }
  }

  const onMissingReasonChange = (reason: string) => {
    setMissingReason(reason)
  }

  const calculateMissingBoxes = (): number | undefined => {
    if (product?.measureUnit === MEASURE_UNITS.box) {
      const boxes = Math.ceil(+missingCounter / product.unitsOfMeasurment)
      return boxes
    }
    return undefined
  }

  const registerMissing = async () => {
    if (!product) return

    try {
      setLoading(true)
      const updateData: UpdateMissingsData = {
        product: {
          id: product.productId,
          expectedQuantity: product.totalUnits,
          barcode: product.barcode
        },
        receiptId,
        isFullMissing: false,
        reportedMissing: +missingCounter,
        reportedMissingBoxes: calculateMissingBoxes()
      }
      await updateMissings(updateData)
      Analytics.receipt.addPartialMissing(
        {
          orderId,
          receiptType,
          quantity: updateData?.reportedMissing ?? 0,
          boxes: updateData?.reportedMissingBoxes
        },
        product
      )

      await validateLastProduct()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const continueToMissing = async () => {
    if (receiptType === RECEIPTS.transfers) {
      if (newTransfersFlow) {
        await registerMissing()
      }
      return
    }

    const redirect = {
      partialmissing: () => {
        partialMissing.go(receiptId, orderId, productId)
      },
      reject: () => {
        rejection.go(receiptId, orderId, productId)
      }
    }[missingReason]
    if (redirect) {
      redirect()
    }
  }

  const persistValidLots = async (validateTotals = false) => {
    const validForms: LotFormValues[] = []
    formConfig.values.forms.forEach((formValues, index) => {
      if (!formConfig.errors.forms?.[index]) {
        validForms.push(formValues)
      }
    })
    if (validForms.length) {
      await saveLotValues(validForms, validateTotals)
    }
  }

  const onAddLot = async (push: <X = any>(obj: X) => void) => {
    await persistValidLots()
    push(emptyForm)
  }

  const goToOrderDetail = () => {
    orderDetail.go(receiptType, receiptId, orderId)
  }

  const onClosePage = async () => {
    if (!product) {
      return
    }

    if (boxesCounter >= expectedQuantity) {
      navigate(-1)
    } else if (boxesCounter < expectedQuantity) {
      onValidateTotals()
    } else if (lotsSaved) {
      await persistValidLots(true)
    } else {
      goToOrderDetail()
    }
  }

  const onAddNewContainer = async () => {
    await persistValidLots()
    containerTypePage.go()
  }

  return {
    formConfig,
    boxesCounter,
    missingDialogRef,
    openMissingDialog,
    product,
    loading,
    container: container || '',
    missingCounter,
    expectedQuantity,
    receiptType,
    newTransfersFlow,
    actions: {
      continueToMissing,
      onMissingReasonChange,
      onAddLot,
      onClosePage,
      onAddNewContainer
    }
  }
}
