import * as Yup from 'yup'
import type { TFunction } from 'i18next'

import type {
  CreateLotData,
  GetProductLotsResponse,
  LotFormValues,
  ProductDetail,
  UpsertProductLotsData
} from 'types/receipt/model'
import { emptyForm } from './ProductLots.hooks'
import { CONTAINER_TYPES, MAX_BOXES, MEASURE_UNITS, REGEXPS } from 'utils/constants'
import { isDateValid } from 'utils/helpers'

export const labelsByMeasureUnit = {
  KG: 'requested-quantity',
  CAJA: 'requested-boxes',
  PIEZA: 'requested-units'
}

export const getUpsertData = (
  receiptId: string,
  container: string,
  product: ProductDetail,
  lotValues: LotFormValues[]
): UpsertProductLotsData => {
  return {
    receiptId,
    product: {
      id: product.productId,
      barcode: product.barcode,
      expectedQuantity: product.totalUnits
    },
    lots: lotValues.map((data) => ({
      lotCode: data.lot,
      expirationDate: data.expirationDate,
      units: +data.numberOfUnits,
      boxes: +data.amountOfBoxes,
      containerName: data.containerName || container
    }))
  }
}

export const getCurrentTotal = (
  values: LotFormValues[],
  measureUnit: string,
  totals: GetProductLotsResponse
) => {
  let total = 0
  if (measureUnit === MEASURE_UNITS.box) {
    total = totals.totalRejectedBoxes + totals.totalMissingBoxes
    values.forEach((value) => (total += +value.amountOfBoxes || 0))
  } else {
    total = totals.totalRejected + totals.totalMissing
    values.forEach((value) => (total += +value.numberOfUnits || 0))
  }
  return total
}

const getMaxTotalValidator = (
  product: ProductDetail | undefined,
  t: TFunction<'global', undefined>,
  totals: GetProductLotsResponse,
  measureUnit: string
) => {
  return (_: number, context: Yup.TestContext<Yup.AnyObject>) => {
    if (!product) {
      return
    }
    const { path, options, createError } = context

    const formValues = (options.context?.forms || []) as LotFormValues[]
    const total = getCurrentTotal(formValues, measureUnit, totals)
    let limit = product.upperTotalLimit
    if (measureUnit === MEASURE_UNITS.box) {
      limit = product.quantity
    }
    if (total > limit) {
      return createError({
        path,
        message: t('validations.greater-total-units')
      })
    }
    return true
  }
}

const getUnitsByBoxesValidator = (
  t: TFunction<'global', undefined>,
  unitsOfMeasurment: number
) => {
  return (_: number, context: Yup.TestContext<Yup.AnyObject>) => {
    const { path, parent, createError } = context

    const boxes: number | undefined = parent.amountOfBoxes
    if (!boxes) {
      return createError({
        path,
        message: t('validations.invalid-amount')
      })
    }
    const expectedUnits = boxes * unitsOfMeasurment
    if (parent.numberOfUnits !== expectedUnits) {
      return createError({
        path,
        message: t('validations.invalid-amount')
      })
    }
    return true
  }
}

const validateLotUniqueness = (
  product: ProductDetail | undefined,
  t: TFunction<'global', undefined>,
  container: string | undefined,
) => {
  return (_: string, context: Yup.TestContext<Yup.AnyObject>) => {
    if (!product) {
      return
    }
    const { path, options, createError } = context

    const formValues = (options.context?.forms || []) as LotFormValues[]

    const set = new Set();
    let err: Yup.CreateErrorOptions = { path: undefined, message: undefined };

    formValues.forEach((val) => {
      const lotContainer = val.containerName ? val.containerName : container;

      if (set.has(lotContainer+'+'+val.lot)) {
        err ={
          path,
          message: t('validations.lot-repeated')
        };
      }

      set.add(lotContainer+'+'+val.lot);
    })

    if (err.path) {
      return createError(err)
    }

    return true;
  }
}

export const getValidationSchema = (
  t: TFunction<'global', undefined>,
  product: ProductDetail | undefined,
  totals: GetProductLotsResponse,
  container: string | undefined,
) => {
  const required = t('validations.required')
  const amount = t('validations.invalid-amount')
  const minDate = new Date()
  minDate.setDate(minDate.getDate() - 1)

  let numberOfUnits = Yup.number().required(required).positive(amount)
  if (product?.measureUnit !== MEASURE_UNITS.kg) {
    numberOfUnits = numberOfUnits.integer()
  }
  if (product?.measureUnit === MEASURE_UNITS.box) {
    numberOfUnits = numberOfUnits.test(
      'unitsByBoxes',
      getUnitsByBoxesValidator(t, product?.unitsOfMeasurment || 0)
    )
  }
  const boxesValidation = Yup.number()
    .required(required)
    .positive(amount)
    .integer(amount)

  return Yup.object({
    forms: Yup.array().of(
      Yup.object({
        lot: Yup.string()
          .required(required)
          .matches(REGEXPS.alphanum, t('validations.alphanum'))
          .test('nameUnique', validateLotUniqueness(product, t, container)),
        expirationDate: Yup.date()
          .transform((_, originalValue: Date | string) => {
            if (
              typeof originalValue === 'string' &&
              !originalValue.match(REGEXPS.date)
            ) {
              return new Error('')
            }

            if (
              typeof originalValue === 'string' &&
              !isDateValid(originalValue)
            ) {
              return new Error('')
            }

            const stringValue = originalValue.toString()
            const parsedDate = originalValue
              ? new Date(stringValue.split('/').reverse().join('/'))
              : null
            return parsedDate
          })
          .typeError(t('validations.invalid-date'))
          .required(required)
          .min(minDate, t('validations.expired-date')),
        amountOfBoxes: Yup.number()
          .concat(
            product?.measureUnit === MEASURE_UNITS.box
              ? boxesValidation.test('maxUnits', getMaxTotalValidator(product, t, totals, MEASURE_UNITS.box))
              : boxesValidation.max(MAX_BOXES, amount)
          ),
        numberOfUnits: numberOfUnits.test(
          'maxUnits',
          getMaxTotalValidator(product, t, totals, MEASURE_UNITS.piece)
        )
      })
    )
  })
}

export const mapLotFormValues = (
  lots: CreateLotData[],
  containerType: string,
  container: string | undefined
): LotFormValues[] => {
  if (!lots.length) {
    return [emptyForm]
  }

  return lots.map((lot) => ({
    lot: lot.lotCode,
    expirationDate: lot.expirationDate,
    numberOfUnits: lot.units.toString(),
    amountOfBoxes: lot.boxes.toString(),
    containerName:
      containerType === CONTAINER_TYPES.total && container
        ? container
        : lot.containerName
  }))
}
