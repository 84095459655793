{
  "loader": {
    "message": "Aguarde, por favor..."
  },
  "commons": {
    "continue": "Continuar",
    "cancel": "Cancelar",
    "finish": "Finalizar",
    "enter": "Ingresar",
    "accept": "Aceptar",
    "confirm": "Confirmar"
  },
  "validations": {
    "temp-range": "La temperatura no está dentro del rango permitido.",
    "generic-error": "Algo salió mal",
    "required": "Campo requerido",
    "invalid-amount": "Ingrese una cantidad válida",
    "greater-total-units": "La cantidad es mayor a la solicitada, escribe la cantidad correcta.",
    "expired-date": "La fecha ingresada está expirada",
    "alphanum": "Ingrese un valor alfanumérico",
    "single-select": "Seleccione una opción",
    "multi-select": "Seleccione al menos una opción",
    "invalid-date": "Formato de fecha inválido",
    "not-enough-lifetime": "No cumple con el tiempo de vida de anaquel",
    "lot-repeated": "El lote no se puede repetir en el mismo contenedor"
  },
  "receipt": {
    "title": "Recibo de proveedores",
    "select-order": "Selecciona una orden:",
    "order-id": "Orden: {{orderId}}",
    "total-sku": "Num. SKU: {{totalSKU}}",
    "type-error": "Se esperaba un arreglo de órdenes en la data del loader",
    "provider": "Proveedor: {{provider}}",
    "sync": "Sincronizar",
    "no-data-title": "No tienes órdenes sincronizadas.",
    "no-data-help": "Para sincronizar, da tap en el botón sincronizar.",
    "chave-accesso": "Llave acceso: {{chave}}"
  },
  "scanner-molecule": {
    "title": "Escanea",
    "use-scanner": "Usa tu escáner"
  },
  "receipt-order-detail": {
    "total-sku": "N.º de sku a recibir",
    "boxes-counter": "Cantidad solicitada: {{received}}/{{total}} unidades",
    "expected-grams": "Cantidad solicitada: {{received}}/{{total}} g",
    "end": "Finalizar",
    "pending": "Por recibir ({{counter}})",
    "finished": "Registrados ({{counter}})",
    "type-error": "Se esperaba una orden de compra en la data del loader",
    "scan-product": "Escanea el producto a recibir",
    "invalid-upc": "Escanee un UPC válido. Se escanéo {{upc}}",
    "nonexistent-upc": "El producto escaneado no está en la orden de compra actual",
    "registered-upc": "El producto escaneado ya ha sido registrado",
    "upc": "UPC: {{upc}}",
    "full-missing": "¿Quieres reportar este producto como faltante completo?",
    "undo-full-missing": "¿Quieres que el producto ya no sea un faltante completo?",
    "missing-error": "Error al marcar el producto como faltante total",
    "manual-upc": "Ingresa manualmente el código del producto",
    "upc-textbox": "Código del producto"
  },
  "receipt-scan-order": {
    "title": "Escanea",
    "scan-label": "Documento de entrada {{type}}",
    "invalid-input": "Escanee un número de orden válido. Se escaneó: {{orderNumber}}",
    "sync-error": "No se ha podido sincronizar la orden: {{orderNumber}}",
    "sync-success": "Orden sincronizada.",
    "dialog": {
      "title": "Escribe el código del documento de entrada:",
      "textbox": "Código"
    }
  },
  "receipt-scan-container": {
    "title": "Escanea",
    "scan-label": "Contenedor",
    "product": "Producto escaneado",
    "product-undefined": "Para escanear un contenedor primero debe escanear un producto",
    "invalid-input": "Escanee un LPN válido. Se escaneó: {{lpn}}",
    "invalid-warehouse-lpn": "Escanee un LPN que pertenezca al almacén actual ({{current}}). Se escaneó: {{scanned}}",
    "displaced-container": "Este contenedor no está en posición de pre-almacenaje. Por favor escoja otro contenedor.",
    "container-used-previously": "Este contenedor ha sido movido previamente en esta orden. Por favor escoja otro contenedor."
  },
  "receipt-product-lots": {
    "measure": "Unidad de medida: {{measure}} unidades por caja",
    "measure-kg": "Unidad de medida: {{measure}} gr por caja",
    "requested-boxes": "Cajas solicitadas",
    "requested-units": "Unidades solicitadas",
    "requested-quantity": "Cantidad solicitada",
    "add-lot": "Agregar lote",
    "end": "Finalizar",
    "type-error": "Se esperaba información de lotes en la data del loader",
    "counter": "{{counter}}/{{total}}",
    "counter-kg": "{{counter}} g/{{total}} g",
    "get-order-error": "Ocurrió un error al recuperar el detalle de la orden"
  },
  "receipt-lot-form": {
    "lot-number": "Lote: {{lotNumber}}",
    "lot": "Lote",
    "expiration": "Fecha de caducidad (dd/mm/aaaa)",
    "units": "Cantidad total de unidades",
    "units-hint": "Ingresa la cantidad total por numero de cajas que hayas agregado en este lote.",
    "boxes": "Cantidad de cajas",
    "grams": "Cantidad en gramos",
    "temperature": "Temperatura (en grados)"
  },
  "receipt-missing-dialog": {
    "missing-type": "Seleciona el tipo de faltante",
    "full-missing": "Faltante completo",
    "rejection": "Rechazo",
    "title": "Te falta recibir {{missing}} unidades ¿cómo las registrarás?",
    "title-kg": "Te falta recibir {{missing}} gramos ¿cómo los registrarás?",
    "title-box": "Te falta recibir {{missing}} cajas ¿cómo las registrarás?",
    "continue": "Continuar",
    "back": "Regresar",
    "reject": "Rechazo de producto",
    "partial-missing": "Reporte de faltante parcial",
    "transfers": {
      "new-title": "Cantidad registrada menor a la solicitada",
      "title": "Para continuar registra las {{missing}} unidades que faltan",
      "title-kg": "Para continuar registra los {{missing}} gramos que faltan",
      "title-box": "Para continuar registra las {{missing}} cajas que faltan",
      "label": "Registraste {{amount}} uds, selecciona Cancelar para corregir o Confirmar para marcarlo como faltantes.",
      "label-kg": "Registraste {{amount}} gramos, selecciona Cancelar para corregir o Confirmar para marcarlo como faltantes.",
      "label-box": "Registraste {{amount}} cajas, selecciona Cancelar para corregir o Confirmar para marcarlo como faltantes."
    }
  },
  "receipt-partial-missing": {
    "title": "Registra el faltante",
    "subtitle": "Escribe la cantidad que falta del producto",
    "total-missing": "Cantidad faltante",
    "total-kg": "Cantidad faltante en gramos",
    "missing-boxes": "Cajas faltantes",
    "register": "Registrar",
    "type-error": "Se esperaba información de totales en la data del loader",
    "missing-error": "Error almacenar el faltante parcial"
  },
  "receipt-rejection": {
    "title": "Registra el rechazo",
    "evidence": "Sube la evidencia",
    "max-evidences": "Máximo 6 fotos.",
    "form-title": "Escribe la cantidad, lote y selecciona el motivo",
    "units": "Unidades a rechazar",
    "units-kg": "Cantidad a rechazar en gramos",
    "boxes": "Cajas a rechazar",
    "lot": "Lote",
    "register": "Registrar",
    "upload-error": "Ocurrió un error al cargar la imagen, intente de nuevo",
    "create-error": "Ocurrió un error al tratar de crear el rechazo",
    "type-error": "Se esperaba la información de un rechazo en la data del loader",
    "missing-photo": "Debes tomar al menos una foto del rechazo",
    "reasons": {
      "NEAR_EXPIRATION": "Caducidad corta",
      "PRODUCT_DAMAGED": "Producto roto",
      "WITHOUT_LABELING": "Producto sin etiquedado",
      "MOLD": "Hongo",
      "NO_FUMIGATION_CERT": "Sin certificado de fumigación",
      "NO_APPOINTMENT": "Proveedor sin cita",
      "OUT_OF_HOURS": "Proveedor fuera de horario",
      "HARMFUL_FAUNA": "Fauna nociva",
      "EXTRA_PRODUCT": "Producto de mas",
      "BAD_UPC": "UPC no coincide",
      "PACKAGING_DAMAGE": "Empaque roto",
      "OUT_OF_RANGE_TEMP": "Temperatura fuera de rango",
      "DEHYDRATED": "Deshidratación",
      "DECAY": "Pudrición",
      "RIPENING": "Maduración",
      "MECHANICAL_DAMAGE": "Daño mecánico",
      "OXIDATION": "Oxidación",
      "SOGGINESS": "Acuosidad",
      "OVER_WEIGHT": "Exceso de peso"
    }
  },
  "receipt-containers": {
    "title": "Contenedores",
    "type-error": "Se esperaba un arreglo de contenedores en la data del loader",
    "toMove": "Por trasladar ({{counter}})",
    "moved": "Trasladado ({{counter}})",
    "container": "Contenedor: {{container}}",
    "skus": "Núm. de skus: {{totalSKU}}",
    "scan-label": "Escanea el contenedor a trasladar.",
    "finish-order": "Finalizar orden",
    "invalid-container": "El contenedor escaneado {{scanned}} no existe en la orden actual",
    "location": "Ubicación escaneada: {{location}}",
    "finish-error": "Ocurrió un error al finalizar el recibo",
    "finish-success": "Recibo finalizado correctamente"
  },
  "receipt-summary": {
    "title": "Resumen",
    "type-error": "Se esperaba un arreglo con el resumen del recibo del producto en la data del loader",
    "lot": "Lote: {{lot}}",
    "amount": "Cantidad: {{amount}} unidades",
    "amount-kg": "Cantidad: {{amount}} g",
    "expiration": "Caducidad: {{expiration}}",
    "container": "Contenedor: {{container}}",
    "reasons": "Motivo: {{reasons}}",
    "temperature": "Temperatura (en grados): {{temperature}}",
    "missing-reason": "Motivo: no llegó",
    "confirm-delete": "¿Está seguro de eliminar el elemento seleccionado?",
    "delete-error": "Ocurrió un error al eliminar el elemento seleccionado",
    "received": "Producto recibido, para ver más ve a Registrados.",
    "received-error": "Ocurrió un error al recibir el producto",
    "status": {
      "received": "Lotes recibidos",
      "rejected": "Rechazados",
      "missing": "Faltante"
    }
  },
  "receipt-scan-location": {
    "title": "Escanea",
    "scan-label": "Ubicación",
    "scanned": "Contenedor trasladado, para ver más ve a trasladados.",
    "move-error": "Ocurrió un error al trasladar el contenedor"
  },
  "receipt-type": {
    "title": "Tipo de recibo",
    "transfers": "Transferencia",
    "providers": "Proveedores",
    "to-receive": "¿Qué vas a recibir?",
    "help": "Da tap en el botón dependiendo de lo que vayas a recibir."
  },
  "receipt-temperature": {
    "title": "Temperatura",
    "temp-label": "Temperatura (en grados)",
    "capture-temp": "Captura temperatura",
    "out-of-range": "Fuera del rango, aprobada por calidad.",
    "update-success": "Temperatura registrada.",
    "update-error": "Ocurrió un error al colocar la temperatura",
    "type-error": "Se esperaba una orden de compra al cargar la pantalla"
  },
  "receipt-container": {
    "title": "Agregar contenedor",
    "label": "¿Que quieres hacer con el contenedor?",
    "text-partial": "Si quieres colocar una parte del producto en el contenedor que tenias y otra parte en un nuevo contenedor selecciona \"Parcial en nuevo\".",
    "text-full": "En caso de querer colocar todo el producto en un nuevo contenedor selecciona \"Total en nuevo\"",
    "partial": "Parcial en nuevo",
    "total": "Total en nuevo"
  },
  "inventory-transfer": {
    "home": {
      "title": "Transferencias de inventario",
      "type-error": "Se esperaba un arreglo de ordenes de salida en la respuesta de receipt-api",
      "skus": "Cant. SKU: {{total}}"
    },
    "detail": {
      "type-error": "Se esperaban datos de una orden desde receipt-api",
      "picked-quantity": "Cantidad ingresada:",
      "location": "Ubicación escaneada:",
      "to-pick": "Por Pickear",
      "picked": "Pickeado",
      "upc-sku": "UPC: {{barcode}} | SKU: {{sku}}",
      "requested": "Cantidad solicitada:",
      "not-to-pick": "No hay nada por pickear",
      "not-picked": "No hay nada pickeado aún",
      "scan-product": "Escanea producto",
      "already-picked": "El producto escaneado ya fue pickeado",
      "not-existing-upc": "El product escaneado no existe en la orden actual",
      "missing": "Faltante",
      "missing-alert": "¿Está seguro que desea marcar el producto como faltante?"
    },
    "pick": {
      "quantity": "Cantidad tomada",
      "quantity-gr": "Cantidad tomada en gramos",
      "scan": "Escanea ubicación",
      "success": "Producto pickeado correctamente."
    },
    "container": {
      "alert": "Deberás escanear un contenedor ya que en la ubicación hay múltiples contenedores."
    }
  }
}