import { useTranslation } from 'react-i18next'
import {
  Filler,
  Icons,
  PageContentAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { ScannerMolecule } from 'components/molecules/Scanner.molecule'
import LoaderAtom from 'components/atoms/Loader.atom'
import { InputDialogOrganism } from 'components/organisms/InputDialog.organism'
import { useScanOrder } from './ScanOrder.hooks'

const ScanOrderPage = () => {
  const { t } = useTranslation('global')
  const { actions, loading, type, dialogRef } = useScanOrder()

  if (loading) {
    return <LoaderAtom>{t('loader.message')}</LoaderAtom>
  }

  return (
    <>
      <ToolbarMolecule
        title={t('receipt-scan-order.title')}
        actionIcon={Icons.CloseIcon}
        onAction={actions.goBack}
        secondaryActionIcon={Icons.IcKeyboardPrimary}
        onSecondaryAction={actions.openDialog}
      />

      <Filler flexGrow={2} />
      <PageContentAtom>
        <ScannerMolecule
          label={t('receipt-scan-order.scan-label', {
            type: t(`receipt-type.${type}`).toLocaleLowerCase()
          })}
          onScan={actions.getOrderInfo}
        />
      </PageContentAtom>
      <Filler />

      <InputDialogOrganism
        dialogRef={dialogRef}
        title={t('receipt-scan-order.dialog.title')}
        textboxLabel={t('receipt-scan-order.dialog.textbox')}
        confirmLabel={t('commons.continue')}
        cancelLabel={t('commons.cancel')}
        confirmAction={actions.getOrderInfo}
      />
    </>
  )
}

export default ScanOrderPage
