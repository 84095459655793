import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Analytics } from 'analytics/analytics.events'
import { useRequiredParams } from 'hooks/params.hook'
import { useConfirmationDialog } from 'components/molecules/ConfirmationDialog.molecule'
import { useGoToOrderDetail } from '../order-detail/OrderDetail.navigator'
import { useGoToTemperature } from '../temperature/Temperature.navigator'
import { syncOrder } from 'client/receipt/receipt.client'
import { DOC_TYPES } from 'utils/constants'
import { toastError, toastSuccess } from 'utils/toast'

export const useScanOrder = () => {
  const { t } = useTranslation('global')
  const navigate = useNavigate()
  const orderDetail = useGoToOrderDetail()
  const temperature = useGoToTemperature()
  const [loading, setLoading] = useState(false)
  const { receiptType } = useRequiredParams('receiptType')
  const { dialogRef, open: openDialog } = useConfirmationDialog()

  const getOrderInfo = (orderNumber: string) => {
    if (isNaN(+orderNumber)) {
      toastError(t('receipt-scan-order.invalid-input', { orderNumber }))
      return
    }

    const documentType = DOC_TYPES[receiptType]
    setLoading(true)
    syncOrder(documentType, orderNumber)
      .then(({ receiptId, metadata: { id, frozenOrRefrigerated } }) => {
        Analytics.receipt.syncDocument({
          orderId: orderNumber,
          receiptType
        })
        toastSuccess(t('receipt-scan-order.sync-success'))
        if (frozenOrRefrigerated) {
          temperature.go(receiptId, receiptType)
        } else {
          orderDetail.go(receiptType, receiptId, id)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  return {
    loading,
    type: receiptType,
    dialogRef,
    actions: {
      getOrderInfo,
      openDialog,
      goBack: () => {
        navigate(-1)
      }
    }
  }
}
