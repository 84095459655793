import { useEffect, useState } from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { useAtom, useAtomValue } from 'jotai'
import { useTranslation } from 'react-i18next'

import type { ReceiptProductSummary } from 'types/receipt/model'
import { useQueryParam, useRequiredParams } from 'hooks/params.hook'
import { useConfirmationDialog } from 'components/molecules/ConfirmationDialog.molecule'
import { scannedContainer, selectedProductAtom } from '../Receipt.state'
import { useGoToOrderDetail } from '../order-detail/OrderDetail.navigator'
import { useGoToProductLots } from '../product-lots/ProductLots.navigator'
import { useGoToRejection } from '../rejection/Rejection.navigator'
import { useGoToPartialMissing } from '../partial-missing/PartialMissing.navigator'
import { useGoToScanContainer } from '../scan-container/ScanContainer.navigator'
import { isAReceiptProductProcessed } from './Summary.loader'
import {
  deleteLotFromData,
  getTotal,
  getUpdateMissingData,
  mapToSummaryData
} from './Summary.helpers'
import {
  deleteRejection,
  upsertProductLots,
  updateMissings
} from 'client/receipt/receipt.client'

export const useSummaryData = () => {
  const { t } = useTranslation('global')
  const { orderId, receiptId, receiptType } = useRequiredParams(
    'orderId',
    'receiptId',
    'receiptType'
  )
  const readonly = useQueryParam('readonly') === 'true'
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [expectedQuantity, setExpectedQuantity] = useState(0)
  const [summary, setSummary] = useState<
  Record<string, ReceiptProductSummary[]>
  >({})
  const product = useAtomValue(selectedProductAtom)
  const [container, setContainer] = useAtom(scannedContainer)
  const loaderData = useLoaderData()
  const orderDetail = useGoToOrderDetail()
  const productLots = useGoToProductLots()
  const scanContainer = useGoToScanContainer()
  const rejection = useGoToRejection()
  const partialMissing = useGoToPartialMissing()
  const { dialogRef, open, value } = useConfirmationDialog()

  if (!isAReceiptProductProcessed(loaderData)) {
    throw new Error(t('receipt-summary.type-error'))
  }

  useEffect(() => {
    if (product) {
      const { total, expectedQuantity } = getTotal(loaderData, product)
      setTotal(total)
      setExpectedQuantity(expectedQuantity)
      setSummary(mapToSummaryData(loaderData))
    }
  }, [product])

  const onEdit = (receipt: ReceiptProductSummary) => {
    if (!product) {
      return
    }

    if (receipt.status === 'received') {
      setContainer(receipt.container)
      productLots.go(receiptId, orderId, product.productId, true)
    } else if (receipt.status === 'rejected') {
      rejection.go(receiptId, orderId, product.productId, receipt.id)
    } else if (receipt.status === 'missing') {
      partialMissing.go(receiptId, orderId, product.productId)
    }
  }

  const onDelete = async () => {
    if (!product) {
      return
    }
    const { receipt, idx } = value as {
      receipt: ReceiptProductSummary
      idx: number
    }

    try {
      setLoading(true)
      if (receipt.status === 'received') {
        await upsertLots(idx)
      } else if (receipt.status === 'rejected') {
        await deleteRejection(
          receiptId,
          loaderData.sapProductId,
          receipt.id || ''
        )
      } else if (receipt.status === 'missing') {
        await onDeleteMissing()
      }

      navigate(0)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const upsertLots = async (index: number) => {
    const upsertData = deleteLotFromData(receiptId, loaderData, index)
    await upsertProductLots(upsertData)
  }

  const onDeleteMissing = async () => {
    const missingData = getUpdateMissingData(receiptId, loaderData)
    await updateMissings(missingData)
  }

  const onCloseSummary = async () => {
    if (!product) {
      return
    }

    if (readonly || total >= expectedQuantity) {
      orderDetail.go(receiptType, receiptId, orderId)
    } else if (total < expectedQuantity) {
      if (container) {
        productLots.go(receiptId, orderId, loaderData.sapProductId)
      } else if (loaderData.received?.lots?.length) {
        setContainer(loaderData.received.lots[0].containerName)
        productLots.go(receiptId, orderId, loaderData.sapProductId)
      } else {
        scanContainer.go(receiptId, orderId)
      }
    }
  }

  return {
    product,
    total,
    summary,
    dialogRef,
    loading,
    readonly,
    expectedQuantity,
    loaderData,
    actions: {
      onEdit,
      openDeleteDialog: open,
      onDelete,
      onCloseSummary
    }
  }
}
